


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { BrokerInformation } from "@/models/broker.model";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";


export default class changePasswordComponent extends Vue {
  public userId = this.$userId;

  public inProcess = false;
  public changePasswordObj: any = {};
  public invalidPassword: any = null;
  public passwordMatched: any=null;
  public invalidNewPassword = false;

  public async changePassword() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) return;
      this.inProcess = true;
      this.changePasswordObj.userId = this.userId;
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyAndChangePassoword",
        this.changePasswordObj);
      if (response.data.data == "Success") {
        this.$snotify.success("Password has been successfully changed.");
        this.$router.push({ name: "AdminDashboard" });
      } else if (response.data.data == "InvalidPassword") {
        this.invalidPassword = "Your current password is incorrect.";
      } else if (response.data.data == "passwordMatched") {
        this.passwordMatched =
          "New password cannot be the same as last five passwords.";
      } else if (response.data.data == "invalidNewPassword") {
        this.invalidNewPassword = true;
      }
      this.inProcess = false;
    } catch (error) {
      console.log(error.response);
      if (error.response.status == 404) {
        this.invalidPassword = "Your current password is incorrect.";
      }if(error.response.status == 405){
          this.passwordMatched ="Current password and new password should not be same.";
      }
      this.inProcess = false;
    }
  }
}
